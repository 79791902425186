<template>
  <div class="badge_custom">
    <!-- <template> -->
    <!-- <v-avatar color="#A5DFF8" size="45">
      <span class="black--text headline">{{ signOfName }}</span>
    </v-avatar> -->
    <!-- <p color="black">{{ fullOfName }}</p> -->
    <!-- </template> -->
    <v-toolbar-items>
      <!-- <v-btn text to="/">Home</v-btn> -->
      <!-- <v-btn text to="/about">About</v-btn> -->
      <v-menu left bottom offset-y close-on-click>
        <template v-slot:activator="{ on }">
          <!-- <v-btn text v-on="on">{{fullOfName}}</v-btn> -->
            <p color="black" text v-on="on">{{ fullOfName.toUpperCase() }}</p>
          <v-avatar color="#A5DFF8" size="45">
      <span v-on="on" class="black--text headline">{{ signOfName }}</span>
    </v-avatar>
        </template>
        <v-list>
          <!-- <v-list-item v-for="n in getMenuItems" @click="() => {}" :key="n.id" :to="n.path">
            <v-list-item-title>{{ n.text }}</v-list-item-title>
          </v-list-item>-->
          <v-list-item v-on:click="userLogout()">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- <v-btn text v-if="!getLoggedIn" to="/register">Register</v-btn> -->
    </v-toolbar-items>
  </div>
  

</template>

<script>
export default {
  props: {
    fullName: String,
    signName: String,
  },
  data() {
    return {
      fullOfName: localStorage.getItem('user-name'),
      signOfName: localStorage.getItem('user-name').substring(0, 1).toUpperCase(),
      
    };
  },
  methods: {
    userLogout(){
      localStorage.clear();
      this.$router.push('/login').catch(error => {
   console.info(error.message)
})
    }
  }
};
</script>

<style scoped>
.badge_custom {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.badge_custom .v-avatar {
  margin-bottom: 15px;
}
.avata-header[data-v-3e201870] {
    margin-top: 3px;
    margin-right: 135px;
    margin-top: 12px;
}
.v-application p {
  margin-right: -178px;
  margin-top: 10px;
}
.v-menu__content {
  margin-top: 10px;
  margin-left: 15px;
}
.v-list-item {
  min-height: 0px !important;
}
</style>
